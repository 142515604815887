body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html{
  scroll-behavior:smooth;
  scroll-padding-top: 78px; /* height of sticky header */

}



input, select {
 
  -webkit-background-clip: text !important;
  background-clip:  text !important;
}

.grecaptcha-badge { visibility: hidden; }

a {
  cursor: pointer;
  border-radius: 0.5rem !important;
  text-transform: none !important;
  font-weight: 600 !important;
}