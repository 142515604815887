/*
.App {
    max-height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
  }
  
  .slice {
    scroll-snap-align: start;
  }
*/

.slice {
  overflow: hidden;
}

.slice-top {
  position: relative;
  z-index: 2;
  background-color: #fff;
}

.slice-container {
  position: relative;
  z-index: 1;
  background-color: #fff;
}

.textColor {
  color: #fff;
  background: none;

}

.CookieConsent {
  max-width: 400px;
  left: 20px !important;
  bottom: 20px;
  border-radius: 0;
  flex-direction: column;
}

.CookieConsent div {
 flex: 0 0 auto !important;
}

/* Media query for mobile devices */
@media (max-width: 767px) {
  .CookieConsent {
    /* Adjust the properties for mobile view */
    max-width: 100%;
    bottom: 0;
    left: 0 !important;
    border-radius: 0;
    /* Example: Full width on mobile devices */
    /* Add other mobile-specific styles here if needed */
  }
}


/* Style the rainbow text element. */
.rainbow-text {
  /* Create a conic gradient. */
  /* Double percentages to avoid blur (#000 10%, #fff 10%, #fff 20%, ...). */
  background: linear-gradient(15deg, #1c1c1c 40%, #5C33D7 49%, #8556e2 50%, #5C33D7 54%, #1c1c1c 60%);

  background-size: 200% 200%;
  background-position: 0 -100%;
  background-repeat: repeat;

  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Animate the text when loading the element. */
  /* This animates it on page load and when hovering out. 
    animation: rainbow-text-animation 2s ease forwards;
    animation-delay: 2s;*/
}

/* Add animation on hover. */
.rainbow-text:hover {
  animation: rainbow-text-animation 0s ease forwards;
}

/* Move the background and make it larger. */
/* Animation shown when hovering over the text. */
@keyframes rainbow-text-animation {
  0% {
    background-position: 0 100%;
  }

  100% {
    background-position: 0 -100%;
  }
}

/* Move the background and make it smaller. */
/* Animation shown when entering the page and after the hover animation. */
@keyframes rainbow-text-animation-back {
  0% {}

  100% {
    background-position: 0 100%;
  }
}

.ccphoneInit {
  animation: 0.6s ease-out topPosition;
  animation-fill-mode: forwards;
}

.ccphone {
  animation: 0.4s ease-out lowerPosition;
  animation-fill-mode: forwards;
}

@keyframes init {
  from {
    transform: rotate(-23deg) scale(0.8)
  }

  to {
    transform: rotate(-23deg) scale(0.8)
  }
}

@keyframes lowerPosition {
  from {
    transform: rotate(15deg) scale(1)
  }

  to {
    transform: rotate(0deg) scale(0.7);
    right: 40%;
    top: 35%;
  }
}

@keyframes topPosition {
  from {
    transform: rotate(0deg) scale(0.7);
    right: 40%;
    top: 35%;
  }

  to {
    transform: rotate(15deg) scale(1)
  }

}

/*
.awFoldOut {
    animation: .4s ease-out awFoldOut;
    animation-fill-mode: forwards;
    animation-delay: 2s;
}

@keyframes awFoldOut {
    from { transform: rotate(0deg);
        left: 0px;
    }
    to { transform: rotate(5deg);
        left: -150px;
     }
  
}
*/

.awFoldOut {
  transform: rotate(5deg);
  left: -10vw;
  opacity: 0;
}

.aaFoldOut {
  transform: rotate(15deg);
  left: 15vw;
  top: 40px;
  opacity: 0;
}


.image-fullwidth::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  box-shadow: 0px -20px 20px -15px #000000 inset;
  pointer-events: none;
  /* This makes sure clicks go through this layer */
}